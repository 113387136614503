/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

.card {
  background-color: transparent !important; /* Removes the box background */
  border: none !important; /* Removes any border */
  box-shadow: none !important; /* Removes any shadow */
}

.App {
  text-align: center;
}
input[type="checkbox"]:checked {
  accent-color: #0e226a;
}

input[type="checkbox"].form-check-input:checked {
  background-color: #0e226a !important;
  border-color: #0e226a !important;
  box-shadow: none !important;
}

.form-check-input:checked {
  accent-color: #0e226a !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.nav-link.active {
  color: white;
}
.App-link {
  color: #61dafb;
}

.container-comments{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.row-comments{
  flex-grow: 0;
}

.growable {
  flex-grow: 1;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.custom-modal-style {
  width: 897px;
  height: 802px;
  }

  nav-link  a{
    color: red !important;
    }   
    .navbar-light .navbar-nav .nav-link {
      color: red;
  }

  .nav-link.active {
   color: rgb(88, 85, 248);
  }

  .footer{
    justify-content:left;
   }

@media(max-width: 576px){
  .response-text{
    font-size: 0.85em;
  }
  .response-text-small{
    font-size: 0.8em;
  }
}


@media only screen and (max-width: 768px) {
  .form-select-mobile {
    font-size: 1.5rem; /* Adjust as needed */
  }
}
   /* .main {
    overflow: hidden;
   
 
} */
/* .header{
  width:100%;
  position: fixed;

}
.head{

  position: fixed;

}
.class1{
  height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
 
}
.activetask{
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
 
} */